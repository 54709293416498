import COLORS from "../../config/layout/colors";

// export const TWITTER_COMSUMER_KEY = '5ZKVOYvGQVVkGdgjZYrxSQ4mV';
// export const TWITTER_CONSUMER_SECRET = 'zy923UEQ6l33jxKHbZeyw9TKTFhtqBur6Iw2DHTXzqPisFKbjo';
// export const GOOGLE_WEBCLIENT_ID = '755122024345-9042f4l129es2kjlimh1m17fs7pr0bf3.apps.googleusercontent.com';

export const COLLECTIONS = {
  NEWS: 'News',
  VIDEOS: 'Youtube',
  GALLERIES: 'Gallery',
  EVENTS: 'Event',
  VIEWED_BY: 'ViewedBy',
  VIEWS: 'View',
  STARED_BY: 'StaredBy',
  SHARED_BY: 'SharedBy',
  SHARES: 'Shares',
  USERS: 'Users',
  POINTS: 'PointStatement',
  POINTS_QUEUE: 'ContactActions',
  HISTORY: 'History',
  WARCRY: 'Chant',
  PLAYERS: 'Player',
  ADS: 'AdvertisingArea',
  EASY:'Easy123',
  APPSETTINGS: 'AppSetting',
  REWARDS: 'Reward',
  DISCOUNTS: 'Discounts',
  LOYALTY_TYPE: 'LoyaltyType',
  HOW_TO_EARN: 'HowToEarn',
  CAMPAIGN_PROGRESS: 'CampaignProgress',
  EVENTS_COMMENTARIES: 'Commentaries',
  STANDINGS: 'Standings',
  NOTIFICATIONS_TOPICS: 'NotificationTopic',
  NOTIFICATION_MESSAGES: 'NotificationMessages',
  FORMATIONS: 'Formation',
  EVENTS_LINEUP: 'Lineup',
  EVENTS_TWEETS: 'Tweets',
  EVENTS_PLAYER_STATISTICS: 'PlayerStatistics',
  EVENTS_PLAYER_ACTIONS: 'Substitutions',
  EVENTS_CONTACT_ACTIONS: 'AccountActions',
  EVENTS_MOTM: 'ManOfTheMatchVotes',
  EVENTS_RATE: 'RateVotes',
  EVENTS_SUBSTITUTION: 'SubstitutionVotes',
  EVENTS_SURVEYS : 'Surveys',
  SURVEY : 'Survey',
  QUIZ: 'Quiz',
  QUESTIONS : 'Question',
  TRAINING : 'Training',

  ANSWER : 'Answers',
  QUESTION_ANSWER : 'AnswersOption',

  EVENTS_SURVEY_QUESTIONS : 'SurveyQuestions',
  EVENTS_SURVEY_ANSWER : 'SurveyAnswers',
  EVENTS_SURVEY_QUESTION_ANSWER : 'SurveyQuestionAnswers',
  GATE_STATUS: 'GateStatus',
  MILESTONES: 'Milestone',
  GENERIC_DML: 'GenericDML',
  REDEMPTIONS: 'Redemptions',
  BADGES: 'Badge',
  PUSH_NOTIFICATION: 'PushNotification',
  CAMPAIGN_MEMBER: 'CampaignMember',
  CPF: 'CPF',
  RECEIPT: 'Receipt',
  CASES: 'Case',

  USER_QUIZ: 'QuizAnswers',
  USER_FIREBASEID: 'UserFirebaseId'
};

export const APP_SETTINGS = {
  APP_VERSION: 'AppVersion',
  REDEMPTION_RECORD_TYPE_ID: 'RedemptionRecordTypeId',
};

export const POINT_ACTIONS = {
  EVENT_CLICK_NEWS: 'ACTION_CLICK_NEWS',
  EVENT_STAR_NEWS: 'ACTION_STAR_NEWS',
  EVENT_SHARE_NEWS: 'ACTION_SHARE_NEWS',

  EVENT_CLICK_GALLERY: 'ACTION_CLICK_GALLERY',
  EVENT_STAR_GALLERY: 'ACTION_STAR_GALLERY',
  EVENT_SHARE_GALLERY: 'ACTION_SHARE_GALLERY',

  EVENT_SHARE_WARCRY: 'ACTION_SHARE_WARCRY',
  EVENT_STAR_WARCRY: 'ACTION_STAR_WARCRY',

  EVENT_SEE_ADS: 'ACTION_SEE_ADS',
  EVENT_CLICK_ADS: 'ACTION_CLICK_ADS',

  DAILY_LOGIN: 'ACTION_DAILY_LOGIN',

  ACTION_UPDATE_PROFILE: 'ACTION_UPDATE_PROFILE',
  ACTION_VOTE_MAN_OF_THE_MATCH: 'ACTION_VOTE_MAN_OF_THE_MATCH',
  ACTION_VOTE_RATE: 'ACTION_VOTE_RATE',
  ACTION_VOTE_SUBSTITUTION_SUGGESTION: 'ACTION_VOTE_SUBSTITUTION_SUGGESTION',

  ACTION_VOTE_SURVEY_ANSWER: 'ACTION_VOTE_SURVEY_ANSWER_',
  ACTION_VOTE_SURVEY_COMPLETE: 'ACTION_VOTE_SURVEY_COMPLETE',

  ACTION_QUIZ_RIGHT_ANSWER: 'ACTION_QUIZ_RIGHT_ANSWER',
  ACTION_QUIZ_WRONG_ANSWER: 'ACTION_QUIZ_WRONG_ANSWER',
  ACTION_QUIZ_COMPLETE: 'ACTION_QUIZ_COMPLETE',

  ACTION_STARTUP_MESSAGE: 'ACTION_STARTUP_MESSAGE_',

  REFER_RECEIVER: 'ACTION_REFER_RECEIVER',
  REFER_SENDER: 'ACTION_REFER_SENDER',

  WELCOME_ACTION: 'ACTION_WELCOME_CONTACT',
};

export const COLLECTIONS_DOCS_ACTIONS = {
  GAME_DAY: 'GameDay',
  SURVEY: 'Survey',
  QUIZ: 'Quiz',
  LOGIN: 'Login',
  NEWS: 'News'
};

export const FAKE_DOMAIN = '@cruzeiro.com.br';

export const NEWS_ARTICLE_URL = 'https://cruzeiro.com.br/noticias/[ARTICLEID]';
export const NEWS_ALTERNATIVE_URL = 'https://www.cruzeiro.com.br/noticias/[ARTICLEID]';
export const NEWS_SITE_ALTERNATIVE__URL = 'https://site.cruzeiro.com.br/noticias/[ARTICLEID]';

export const DEFAULT_LOYALTY_CATEGORY = 'NAÇÃO AZUL';

export const INGRESSE_API_KEY = 'cghkQAY234L3o09njChuSAS8EUkcugfq';

export const TEAMS = {
  ALL: {
    label: 'Tudo',
    hasEvents: true,
    colors: {
      bg: COLORS.TRANSPARENT.T,
      text: COLORS.BRAND.GOLD,
      border: COLORS.BRAND.GOLD,
    },
  },
  CRU: {
    label: 'Masculino',
    code: 'CRU', 
    hasEvents: true,
    colors: {
      bg: COLORS.BRAND.CRUZEIRO,
      text: COLORS.WHITE.W,
      border: COLORS.BRAND.CRUZEIRO,
    }
  },
  CRUFEM: {
    label: 'Feminino',
    code: 'CRUFEM', 
    hasEvents: true,
    colors: {
      bg: COLORS.BRAND.LIGHT,
      text: COLORS.WHITE.W,
      border: COLORS.BRAND.LIGHT,
    }
  },
  CRUBASE: {
    label: 'Base',
    hasEvents: true,
    alias: ['CRU17','CRU20'],
    colors: {
      bg: COLORS.WHITE.W,
      text: COLORS.BRAND.DARK,
      border: COLORS.WHITE.W,
    }
  },
  All: {
    label: 'Institucional',
    hasEvents: false,
    colors: {
      bg: COLORS.TRANSPARENT.T,
      text: COLORS.WHITE.W,
      border: COLORS.WHITE.W48,
    }
  },
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from '@emotion/styled';
import logo from '../../assets/logo.png';
import adidas from '../../assets/footer/adidas_23_menorcopy.png';
import supermercadobh from '../../assets/footer/bh_png_copyMNEOR.png';
import betfair from '../../assets/footer/betfair_branca180x101.png';
import gerdau from '../../assets/footer/gerdauhorizontal.png';
import mm from '../../assets/footer/logo_nova-MMenor.png';
import brahma from '../../assets/footer/Brahma_Chopp_menor_copy.png';
import multivix from '../../assets/footer/multivix_branco_teste.png';
import vilmaLogo from '../../assets/footer/vilma_logo.png';
import kodilarLogo from '../../assets/footer/kodilar_logo.png';
import cimed from '../../assets/footer/cimed_branco_mencopy.png';
import solides from '../../assets/footer/solides_branco_.png';
import statsperform from '../../assets/footer/stats_perform_logo.png';
import surfLogo from '../../assets/footer/surf_logo.png';
import tnt from '../../assets/footer/logo-tnt_v2-vermelho-com-branco-copiar-_1_1-01.png';
import matchshirt from '../../assets/footer/matchshirt.png';
import cemig from '../../assets/footer/cemig.png';
import bpconsorcio from '../../assets/footer/bpconsorcio_logo.png';
import Txt from "../Atoms/Txt";
import { X} from "react-feather";
import { FaWhatsapp } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
import { SlEarphonesAlt } from "react-icons/sl";
import { FaAngleRight, FaRegEnvelope } from "react-icons/fa6";
import { BREAKPOINTS } from "../configs/consts";
import Link from "../Atoms/Link";


const Footer = (props) => {

    const { config, routes } = props;

    const linksMocks = [] 
    /* Estes links serão vistos futuramente
    = [
        {
            title: "o clube",
            itens: [
                { title: "Cruzeiro SAF", link: "#" },
                { title: "História", link: "#" },
                { title: "Estrutura", link: "#" },
                { title: "Sustentabilidade", link: "#" },
                { title: "Transparência", link: "#" },
                { title: "Projetos incentivados", link: "#" }
            ]
        },
        {
            title: "futebol",
            itens: [
                { title: "Profissional Masculino", link: "#" },
                { title: "Profissional Feminino", link: "#" },
                { title: "Categoria de Base", link: "#" },
                { title: "Escolas de Futebol", link: "#" },
                { title: "Cruzeiro Global Business", link: "#" },
                { title: "Uniformes", link: "#" }
            ]
        },
        {
            title: "notícias e imprensa",
            itens: [
                { title: "Editorias", link: "#" },
                { title: "Área de imprensa", link: "#" },
                { title: "Mídias", link: "#" },
            ]
        },
        {
            title: "esportes",
            itens: [
                { title: "Atletismo", link: "#" },
                { title: "Área de basquete", link: "#" },
                { title: "Vôlei", link: "#" },
                { title: "Futebol Americano", link: "#" }
            ]
        },
        {
            title: "produtos e serviços",
            itens: [
                { title: "Loja do Cruzeiro", link: "#" },
                { title: "Produtos", link: "#" },
                { title: "Denuncie Pirataria", link: "#" }
            ]
        },
        {
            title: "negócios",
            itens: [
                { title: "Patrocinadores", link: "#" },
                { title: "Seja um patrocinador", link: "#" },
                { title: "Seja um Licenciado", link: "#" },
                { title: "Seja uma Escola Licenciada", link: "#" },
                { title: "Projetos Incentivados", link: "#" },
                { title: "Contato Comercial", link: "#" }
            ]
        }
    ] */

    const [showSupport, setShowSupport] = useState(false);

    return (
        <FooterContainer config={config}>
            {/* <LogoFooterContainer>
                <img src={logo} alt="Logo" />
            </LogoFooterContainer> */}
            <AllFootterContentContainer {...props}>
                <SponsorsContainer>
                    <SponsorsContentContainer opacity={0.7}>
                      <Link href="https://www.adidas.com.br/cruzeiro" target="_blank">
                        <img src={adidas} alt="adidas" />
                      </Link>
                      <Link href="https://apostas.betfair.com/palpites-cruzeiro/" target="_blank">
                        <img src={betfair} alt="betfair" />
                        </Link>
                      <Link href="http://supermercadosbh.com.br/" target="_blank">
                        <img src={supermercadobh} alt="supermercadobh" />
                      </Link>
                    </SponsorsContentContainer>
                    <SponsorsContentContainer opacity={0.7} twoRows={true}>
                      <Link href="https://www.bemprotege.com.br/" target="_blank">
                        <img src={bpconsorcio} alt="bemprotege" />
                      </Link>
                      <Link href="https://www.brahma.com.br/" target="_blank">
                        <img src={brahma} alt="brahma" />
                      </Link>
                      <Link href="https://www.comprecimed.com.br/" target="_blank">
                        <img src={cimed} alt="cimed" />
                      </Link>
                      <Link href="https://kodilar.com.br/" target="_blank">
                        <img src={kodilarLogo} alt="kodilarLogo" />
                      </Link>
                      <Link href="http://www.gerdau.com.br/" target="_blank">
                        <img src={gerdau} alt="gerdaur" />
                      </Link>
                      <Link href="https://mmalugueldecarros.com/" target="_blank">
                        <img src={mm} alt="mm" />
                      </Link>
                      <Link href="http://www.multivix.edu.br/" target="_blank">
                        <img src={multivix} alt="multivix" />
                      </Link>
                      <Link href="https://www.vilma.com.br/" target="_blank">
                        <img src={vilmaLogo} alt="vilmaLogo" />
                      </Link>
                      <Link href="https://www.unilever.com.br/brands/home-care/surf/" target="_blank">
                        <img src={surfLogo} alt="surfLogo" />
                      </Link>
                    </SponsorsContentContainer>
                    <SponsorsContentContainer gap={"46px"} width={"550px"} opacity={0.7} hasBorderBottom={false} twoRows={true}>
                      <Link href="https://cemigsim.com.br/" target="_blank">
                        <img src={cemig} alt="cemig" />
                      </Link>
                      <Link href="https://www.matchwornshirt.com/" target="_blank">
                        <img src={matchshirt} alt="matchshirt" />
                      </Link>
                      <Link href="https://solides.com.br/" target="_blank">
                        <img src={solides} alt="solides" />
                      </Link>
                      <Link href="https://www.grupopetropolis.com.br/marcas/nao-alcoolicos/tnt-energy-drink/" target="_blank">
                        <img src={tnt} alt="tnt" />
                      </Link>
                      <Link href="https://www.statsperform.com/" target="_blank">
                        <img src={statsperform} alt="statsperform" />
                      </Link>
                    </SponsorsContentContainer>
                </SponsorsContainer>
                {/* <LogoFooterContentContainer>
                    <img src={logo} alt="Logo" />
                </LogoFooterContentContainer> */}
                {linksMocks?.length > 0 && (
                  <GridContainer>
                    <LinksFooter type="o clube" linksMocks={linksMocks} />
                    <LinksFooter type="futebol" linksMocks={linksMocks} />
                    <LinksFooter type="notícias e imprensa" linksMocks={linksMocks} />
                    <LinksFooter type="esportes" linksMocks={linksMocks} />
                    <LinksFooter type="produtos e serviços" linksMocks={linksMocks} />
                    <LinksFooter type="negócios" linksMocks={linksMocks} />
                  </GridContainer>
                )}
                <div className="copyrights-container">
                  <Txt typo="caption-lg-footer">
                    © 2024 Cruzeiro SAF. Todos os direitos reservados.
                  </Txt>
                </div>
            </AllFootterContentContainer>
        {showSupport && <SupportModalOptionsContainer config={config} routes={routes} setShowSupport={setShowSupport} />}
        <SupportMenuBottom config={config} setShowSupport={setShowSupport} />
        <SupportMenuMobileBottom config={config} setShowSupport={setShowSupport} showSupport={showSupport} />
        </FooterContainer>
    );
}


export default Footer;




const SupportMenuContainer = styled.div`
    /* Frame 1801 */
    /* Auto layout */
    display: flex;
    z-index: 9999;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 12px;

    position: fixed;// 
    bottom: 27px; /* Espaçamento no fundo */
    right: 27px; /* Espaçamento na margem direita */
    cursor: pointer;
    width: 172px;
    height: 56px;

    /* Brand / Blue Base */
    background: ${props => props.config?.colors?.BRAND.BASE};
    border-radius: 360px;

    @media screen and (max-width: 786px) {
        display: none;
    }
`;



const SupportMenuMobileContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    gap: 12px;

    position: fixed; 
    bottom: 42px;
    right: 32px; 
    cursor: pointer;
    width: ${props => props?.showSupport ? "140px": "56px"};  
    height: 56px;

    /* Brand / Blue Base */
    background: ${props => props.config?.colors?.BRAND.BASE};
    border-radius: 360px;
    z-index: 999;

    @media screen and (min-width: 769px) {
        display: none;
    }
`;




const SupportItemOptionsRow = styled.div`
    display: flex;
    align-items: center;
    width: 368px;
    height: 48px;
    justify-content: space-between;
    padding: 12px 8px 12px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;




const SupportOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
    width: 328px;
    
`;


const SupportTitleContainer = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 8px;
    gap: 84px;
    border-radius: 8px 8px 0 0;

    width: 368px;

    /* Brand / Blue Base */
    background: ${props => props.config?.colors?.BRAND.BASE};

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    @media screen and (max-width: 786px) {
        width: 320px;
    };
`;



const SupportContainer = styled.div`
  display: flex;
  z-index: 9999;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  position: fixed;
  bottom: 100px; /* Espaçamento no fundo */
  right: 32px; /* Espaçamento na margem direita */
  width: 368px;

  /* White / 100 */
  background: #FFFFFF;
  border-radius: 8px;

  @media screen and (max-width: 786px) {
    bottom: 170px;
    position: fixed;
    width: 320px;
  }
`;



const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px; /* Espaço entre as células */

    @media screen and (max-width: 786px) {
        grid-template-columns: 1fr; /* Altera para uma única coluna */
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    
    & > :nth-of-type(2) {
        margin-bottom: 10px; /* Adicione a margem para a segunda coluna */
    }
`;

const GridItem = styled.div`
    padding: 10px;
    @media screen and (max-width: 786px) {
        justify-content: center;
        align-items: center;
    }
 `;


const LinksItemFooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    @media screen and (max-width: 786px) {
        justify-content: center;
        align-items: center;
    }
 `;


const LinksOptionsItemFooterContainer = styled.div`
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    font-style: normal !important;

    width: fit-content;
    height: fit-content;

    opacity: 0.8;

    flex: none;
    flex-grow: 0;

    @media screen and (max-width: 786px) {
        justify-content: center;
        align-items: center;
    }
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    background-color:  ${props => props.config?.colors?.BRAND.DARK};
    padding: 40px 0px 160px 0px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;
        padding: 40px 0px 180px 0px;
    }
`;

const LogoFooterContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin: 30px;

    img {
      width: 89px;
      height: 89px;
      border-radius: 300px;
      object-fit: cover;
    }
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const LogoFooterContainer = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  left: calc(((50vw - 470px) / 2) - 45px);
  justify-content: center;
  align-content: center;
  
  @media screen and (max-width: ${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    display: none;
  }

  img {
    width: 90px;
    height: 90px;
    left: 151px;
    border-radius: 300px;
    object-fit: cover;
    margin-top: calc(100vh * 0.35);
  }
`;

const AllFootterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 786px) {
    justify-content: center;
    align-items: center;
  }

  .copyrights-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    span {
      color: ${props => props.config?.colors?.WHITE.W};
    }
  }
`;




const SponsorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 940px;
  max-width: calc(100vw - 32px);
  top: 20px;
`;

const SponsorsContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${props => props?.twoRows ? "flex-start" : "center"};
  padding: 32px 0px 32px 0px; 
  gap:  ${props => props?.gap ? props?.gap : "60.56px"};
  opacity: ${props => props?.opacity ? props?.opacity : 1};
  width: 100%;
  max-width: ${props => props?.width ? props?.width : "940px"}; 
  /* White / 32 */
  border-bottom: ${props => props?.hasBorderBottom === false ? "none" : "1px solid rgba(255, 255, 255, 0.32)"};
  flex-wrap: ${props => props?.twoRows ? "wrap" : "nowrap"};
  
  @media screen and (max-width: 768px) {
    gap: ${props => props?.twoRows ? "60px" : "30px"};
    justify-content: center;
    align-items: center;
    padding: 32px 0px 32px 0px;
    margin: 0 16px;
  }
  
  @media screen and (max-width: 374px) {
    flex-wrap: wrap;
  }

  a {
    flex-grow: 0;
    object-fit: cover;

    img{
      height: 41px;
    }
  }
`;

const StyledTxt = styled(Txt)`
  margin: 0 auto;
  width: 243px;
  height: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF; 
`;
const StyledX = styled(X)`
  margin: 0 auto;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const SupportItemTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const SupportItemIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  @media screen and (max-width: 786px) {
    padding: 50px;
  };
`;

function SupportModalOptionsContainer(props) {
  return (
    <SupportContainer>
      <SupportTitleContainer config={props.config}>
          <StyledTxt typo="subtitle-lg">segunda a sexta, das 8h às 18h</StyledTxt>
          <StyledX onClick={() => props.setShowSupport(false)} color="white" />
      </SupportTitleContainer>
      <SupportOptionsContainer>
        <Link href="https://api.whatsapp.com/send?phone=553140002161" target="_blank" style={{ cursor: 'pointer' }} >
          <SupportItemOptionsRow>
              <SupportItemTextContainer>
                <FaWhatsapp color={props.config?.colors?.BRAND.DARK} size={32} />
                <Txt color={props.config?.colors?.BRAND.DARK} typo="paragraph-md" >Whatsapp</Txt>
              </SupportItemTextContainer>
              <SupportItemIconContainer>
                  <FaAngleRight color={props.config?.colors?.BRAND.BASE} size={16} />
              </SupportItemIconContainer>
          </SupportItemOptionsRow>
        </Link>

        <Link to={props.routes.caseNew.path} target="_blank" style={{ cursor: 'pointer' }} >
          <SupportItemOptionsRow>
            <SupportItemTextContainer>
              <FaRegEdit color={props.config?.colors?.BRAND.DARK} size={32} />
              <Txt color={props.config?.colors?.BRAND.DARK} typo="paragraph-md" >Abrir Chamado</Txt>
            </SupportItemTextContainer>
            <SupportItemIconContainer>
                <FaAngleRight color={props.config?.colors?.BRAND.BASE} size={16} />
            </SupportItemIconContainer>
          </SupportItemOptionsRow>
        </Link>
        <Link href="mailto:contato@cruzeiro.com.br">
          <SupportItemOptionsRow>
            <SupportItemTextContainer>
              <FaRegEnvelope color={props.config?.colors?.BRAND.DARK} size={32} />
              <Txt color={props.config?.colors?.BRAND.DARK} typo="paragraph-md" >contato@cruzeiro.com.br</Txt>
            </SupportItemTextContainer>
          </SupportItemOptionsRow>
        </Link>
        <Link href="tel:553140002161">
          <SupportItemOptionsRow>
            <SupportItemTextContainer>
              <LuPhone color={props.config?.colors?.BRAND.DARK} size={32} />
              <Txt color={props.config?.colors?.BRAND.DARK} typo="paragraph-md" >+55 31 4000-2161</Txt>
            </SupportItemTextContainer>
          </SupportItemOptionsRow>
        </Link>
      </SupportOptionsContainer>
    </SupportContainer>
  )
}

function SupportMenuBottom(props) {
    return (
        <SupportMenuContainer config={props.config} onClick={() => props?.setShowSupport(n => !n)}>
            <div style={{ padding: "8px", gap: "8px" }}>
                <SlEarphonesAlt width={32} height={32} color="white" style={{ gap: "8px" }} />
            </div>
            <Txt color="white" typo="subtitle-lg">Atendimento</Txt>
        </SupportMenuContainer>
    )
}

function SupportMenuMobileBottom(props) {
    return (
        <SupportMenuMobileContainer config={props.config} onClick={() => props?.setShowSupport(n => !n)} showSupport={props?.showSupport} >
            <div style={{ padding: "8px", gap: "8px" }}>
                <SlEarphonesAlt width={24} height={24} color="white" />
            </div>
            {props?.showSupport && <Txt color="white" typo="subtitle-lg">Atendimento</Txt>}
        </SupportMenuMobileContainer>
    )
}

function LinksFooter({ type, linksMocks }) {
  return (
    <GridItem >
      {linksMocks
        ?.filter(item => item?.title.includes(type))
        .map((item, index) => (
          <LinksItemFooterContainer key={index}>
            <Txt color="white" typo="heading-footer-lg" style={{ fontStyle: "normal", fontWeight: "normal" }}>{item.title}</Txt>
            <LinksOptionsItemFooterContainer>
              {item?.itens.map((options, index) => (
                <a key={index} href={options?.link} target={'_blank'} style={{ cursor: 'pointer' }} rel="noreferrer"  >
                  <Txt color="white" typo="caption-lg-footer" style={{ lineHeight: "18.2px", letterSpacing: "normal", fontStyle: "normal"}}>{options?.title}</Txt>
                </a>
              ))}
            </LinksOptionsItemFooterContainer>
          </LinksItemFooterContainer>
        ))}
    </GridItem>
  );
}

















